.pagination > li {
  list-style: none;
  display: inline-block;
  user-select: none;
}
.pagination > li > a,
.pagination > li > span {
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: "black";
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.paginationActive {
  color: #fff;
  background-color: #218838;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: #218838;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border: 1px solid #e5e5e5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
