@import "normalize.css";
@import "@fontsource/lato/scss/mixins.scss";

@include fontFace(
  $weight: 400, //400 = Regular  
);

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'Lato';
}

input, .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: calc(500px - 20px);
  padding: 10px 14px;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  border: 1px solid rgba(186, 186, 186, 0.4);
  border-radius: 10px;
}

input::placeholder {
  color: #aab7c4;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
}

input:focus, .StripeElement--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement, .StripeElement.FpxBankElement, .StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

nav li .Dropdown-arrow {
  top: 18px;
}

/* Safari 6.2,7.1+ */
_::-webkit-full-page-media, _:future, :root .loginForm { min-height: 400px; }