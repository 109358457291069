.progress-div {
    background-color: #C4C4C4;
    border-radius: 6px;
}

.progressBar {
    background: #00FF94;
    height: 6px;
    transition: 1s ease;
    transition-delay: 0.5s;
    border-radius: 6px;
    margin: 0;
}

.progressComp {
    padding: 0 1rem;
    background: #1E1029;
}

.progressCompMobile {
    padding: 0 1rem;
    background: transparent;
}

.progressComp h1 {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.38px;
    color: #00FF94;
}

.progressCompMobile h1 {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.38px;
    color: #00FF94;
}

.percent-number {
    color: #C4C4C4;
}